/*
 * follow the link to see why fonts placed here
 * https://github.com/vercel/next.js/issues/34462
 */
/* Work Sans. */
  @font-face {
    font-family: 'Work Sans';
    src: url('/fonts/WorkSans-Regular.ttf') format('truetype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'Work Sans';
    src: url('/fonts/WorkSans-Medium.ttf') format('truetype');
    font-weight: 500;
  }
  @font-face {
    font-family: 'Work Sans';
    src: url('/fonts/WorkSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }
  @font-face {
    font-family: 'Work Sans';
    src: url('/fonts/WorkSans-Bold.ttf') format('truetype');
    font-weight: 700;
  }
